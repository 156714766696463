<template>
  <div class="slider">
    <swiper
        :modules="modules"
        class="slider--slides-wrapper"
        :autoplay="autoplay"
    >
      <swiper-slide v-for="image in images" :key="`slide-${image.src}`">
        <img
            :src="image.src"
            :alt="image.alt"
            decoding="async"
        >
      </swiper-slide>
    </swiper>

    <picture>
      <source :srcset="backgroundSources.xs.src" :media="backgroundSources.xs.media">
      <source :srcset="backgroundSources.md.src" :media="backgroundSources.md.media">
      <img
          :src="backgroundSources.default.src"
          :alt="backgroundAlt"
          decoding="async"
      >
    </picture>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';

defineProps({
  images: {
    type: Array,
    default: () => [],
  },
  backgroundSources: {
    type: Object,
    default: () => ({
      xs: {
        media: '(max-width: 768px)',
        src: '/imac-left-s.png'
      },
      md: {
        media: '(max-width: 980px)',
        src: '/imac-left-m.png'
      },
      default: {
        src: '/imac-left.png'
      },
    }),
  }
  ,
  backgroundAlt: {
    type: String,
    default: 'Slide show on iMac',
  },
  autoplay: {
    default: true,
  },
})

const modules = [ Autoplay ];
</script>

<style scoped>
.slider {
  display: inline-block;
  height: 401px;
  width: 401px;
  min-width: 401px;
  position: relative;
}

.slider > picture > img {
  width: 100%;
  height: 100%;
}

.slider--slides-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  transform: matrix3d(1.117, 0.17, 0, 0.00084, 0.035, 0.61, 0, -0.00015, 0, 0, 1, 0, 21.7, 27, 0, 1);
  transform-origin: 0px 0px;
  height: 401px;
  background-color: #030303;
}
::v-deep .swiper-slide > img {
  width: 401px;
  height: 401px;
}
</style>
